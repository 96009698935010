import { Component, OnInit, ViewChild } from '@angular/core';
import { DataServiceService } from 'src/app/services/api-data-service';
import { MatSnackBarComponent } from 'src/app/sharecomponent/mat-snack-bar/mat-snack-bar.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  constructor(private apiService:DataServiceService,private spinner:NgxSpinnerService,private _snackBar:MatSnackBarComponent) { }
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayedColumns = ['name', 'enquiry','validity', 'price', 'status'];
  packageList = [];
  dataSource: any;
  totalList: number = 0;
  ngOnInit() {
    this.getData();
  }
  getData() {
    this.spinner.show();
    let obj: any = {};
    this.apiService.getData('/package/allList').then((data: any) => {
      this.spinner.hide();
      this.packageList = data.data;
      console.log(this.packageList);
      this.dataSource = new MatTableDataSource(this.packageList);
      this.totalList= data.totalCount;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, err => {
      this.spinner.hide();
    })
  }

  toggleActive(event,row){
    let requestBody = { id: row._id,  status: event.checked}
    this.apiService.postData('/package/status', requestBody ).then((data: any) => {
      this.spinner.hide();
      this._snackBar.openSnackBar('status updated successfully', 'Close', 'green-snackbar');
      this.getData();
    }, err => {
      this.spinner.hide();
    })
  }
  validity =  ['lifetime']

  packageObj ={
    name: '',
    price: 0,
    validity: '',
    totalEnquiry: 0,
  }

  hide=true;
  addPackage(){
      this.hide = !this.hide;
  }
  savePackage(form, obj) {
    if(!form.valid){
      this._snackBar.openSnackBar('please insert require details', 'Close', 'red-snackbar');
    }else if(obj.price <=0){
      this._snackBar.openSnackBar('price must be greater than 0', 'Close', 'red-snackbar');
    }else if(obj.totalEnquiry <=0){
      this._snackBar.openSnackBar('enquiry must be greater than 0', 'Close', 'red-snackbar');
    }else{
      Swal.fire({
        title: 'Are you sure want add this record?',
        // text: 'You will not be able to recover this file!',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, Save it!',
        cancelButtonText: 'No, cancel it'
      }).then((result) => {
        if (result.value) {
              this.spinner.show();
              this.apiService.postData('/package/add', obj).then((data: any) => {
                this.spinner.hide();
                this.packageObj = {
                  name: '',
                  price: 0,
                  validity: '',
                  totalEnquiry: 0,
                }
                this.getData();
                this._snackBar.openSnackBar('package added successfully', 'Close', 'green-snackbar');
                // this.dataSource.paginator = this.paginator;
                // this.dataSource.sort = this.sort;
              }, err => {
                this.spinner.hide();
              })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel")
        }
      })
    }

  }
}
