import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { DataServiceService } from 'src/app/services/api-data-service';
import { MatSnackBarComponent } from 'src/app/sharecomponent/mat-snack-bar/mat-snack-bar.component';
import { ActivatedRoute } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-approve-post',
  templateUrl: './approve-post.component.html',
  styleUrls: ['./approve-post.component.css']
})
export class ApprovePostComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;

  displayedColumns = ['name', 'email','contact','userType','usedPackage', 'availPackage', 'status', 'block'];

  userData:any=[];
  dataSource:any;
  pageSize=5;
  page=0;
  searchUser='';

  applyFilter() {
    if(this.searchUser.trim()==''){
      this.snackBar.openSnackBar("Insert search email",'Close','red-snackbar');
    }else{
    this.searchUser = this.searchUser.trim(); // Remove whitespace
    this.searchUser = this.searchUser.toLowerCase(); // Datasource defaults to lowercase matches
    this.spinner.show();
    let obj = {
      email : this.searchUser
    }
    this.apiService.getData('/user/userFilter',obj).then((data:any) => {
          this.userData=data.data;
          this.dataSource = new MatTableDataSource( this.userData);
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
          this.tableLength=data.count;
          this.spinner.hide();
        },err => {
          this.spinner.hide();
    })
  }
 }
 removeFilter(){
    this.searchUser='';
    this.getData(0);
 }

  constructor(private apiService:DataServiceService,private spinner:NgxSpinnerService,private snackBar: MatSnackBarComponent) { }

  ngOnInit() {
      this.getData(this.page);
  }
  
  pageChange(event){
    if(this.searchUser!==''){
      this.snackBar.openSnackBar("Please remove filter email",'Close','red-snackbar');
    }else{
      this.page=event.pageIndex;
      let page =  event.pageIndex * this.pageSize;
      this.getData(page);
    }
  }
  tableLength =0;

  getData(page){
    let obj:any={
      skip: page,
      limit: this.pageSize
    };
    this.spinner.show();
    this.apiService.getData('/user/userList',obj).then((data:any) => {
          this.userData=data.data;
          this.dataSource = new MatTableDataSource( this.userData);
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
          this.tableLength=data.count;
          this.spinner.hide();
        },err => {
          this.spinner.hide();
    })
  }

  toggleActive(event, row){
    let obj = {
      id: row._id,
      status: event.checked
    }
    this.spinner.show();
    this.apiService.postData('/user/status',obj).then((data:any) => {
          this.snackBar.openSnackBar("Status updated!!",'Close','green-snackbar');
          this.spinner.hide();
        },err => {
          this.spinner.hide();
    })
  }

  toggleBlock(event, row){
    let obj = {
      id: row._id,
      status: event.checked
    }
    this.spinner.show();
    this.apiService.postData('/user/blockStatus',obj).then((data:any) => {
          this.snackBar.openSnackBar("Status updated!!",'Close','green-snackbar');
          this.spinner.hide();
        },err => {
          this.spinner.hide();
    })
  }

}
