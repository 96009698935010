import { Component, OnInit } from '@angular/core';
import { DataServiceService } from 'src/app/services/api-data-service';
import { MatSnackBarComponent } from 'src/app/sharecomponent/mat-snack-bar/mat-snack-bar.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-barcodelist',
  templateUrl: './barcodelist.component.html',
  styleUrls: ['./barcodelist.component.css']
})
export class BarcodelistComponent implements OnInit {

  constructor(private apiservice:DataServiceService,private spinner:NgxSpinnerService,private _snackBar:MatSnackBarComponent) { }
  qrlist:any=[];
  ngOnInit() {
    this.getdata();
  }
  getdata(){
    let data:any={};
    this.apiservice.getData('/vehical/getdata',data).then((data:any) => {
      console.log(data);
      this.qrlist=data.data;
      this.spinner.hide();
  },err => {
    this.spinner.hide();
  //this._snackBar.openSnackBar('Error While Register','Close','red-snackbar');
})
  }
}
