import { Component, OnInit } from '@angular/core';
import { DataServiceService } from 'src/app/services/api-data-service';
import { MatSnackBarComponent } from 'src/app/sharecomponent/mat-snack-bar/mat-snack-bar.component';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private apiService:DataServiceService,private spinner:NgxSpinnerService,private _snackBar:MatSnackBarComponent,private http: HttpClient) { }

  ngOnInit() {
    this.getDashboardData();
  }
  data = [];
  otherInfo = {
    activeUser:0,
    totalUser:0,
    totalEnquiry:0,
    todayEnquiries:0
  }
  getDashboardData(){
    let obj:any={};
    this.spinner.show();
    this.apiService.getData('/user/adminDashboard', obj).then((data:any) => {
          this.data=data.data;
          this.otherInfo= data.otherInfo;
          this.spinner.hide();
        },err => {
          this.spinner.hide();
    })
  }
}
