import { Component, OnInit } from '@angular/core';
import { DataServiceService } from 'src/app/services/api-data-service';
import { MatSnackBarComponent } from 'src/app/sharecomponent/mat-snack-bar/mat-snack-bar.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.css']
})
export class AddPostComponent implements OnInit {

  constructor(private api:DataServiceService,private _snackBar:MatSnackBarComponent,private http: HttpClient) { }


  userid:any='';
  usercontact:any='';
  finaldata=[];
  UserInfo:any={
    firstName: '',
    lastName:'',
    email: '',
    mobileNumber:'',
    isActive:false,
    isBlocked: false,
  };
  isActiveList:any=[true,false];
  isBlockedList:any=[true,false];

  ngOnInit() {

  }
  
  filename:any=''
  imagedata:File=null;
  selectedFile: File = null;
  post_images:any=[];

  submitdata(form,data){
    if(form.invalid){
      return; 
  }else{
    }
  }
}
