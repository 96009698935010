import { Component, OnInit, ViewChild } from '@angular/core';
import { DataServiceService } from 'src/app/services/api-data-service';
import { MatSnackBarComponent } from 'src/app/sharecomponent/mat-snack-bar/mat-snack-bar.component';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-add-restaurant',
  templateUrl: './add-restaurant.component.html',
  styleUrls: ['./add-restaurant.component.css']
})
export class AddRestaurantComponent implements OnInit {

  dataSource:any;
  applyFilter(filterValue: string) {
   filterValue = filterValue.trim(); // Remove whitespace
   filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
   this.email = filterValue;
 }
//  ngAfterViewInit() {
//   this.dataSource = new MatTableDataSource( this.userdata);
//   this.dataSource.paginator = this.paginator;
//   this.dataSource.sort = this.sort;
// }
  constructor(private apiService:DataServiceService,private snackBar: MatSnackBarComponent,private spinner:NgxSpinnerService) { 
  }
  email=''
  userData={
    name: '',
    email: '',
    contact: '',
    address: '',
    _id: ''
  };
  packageList = [];
  ngOnInit() {
     // this.getdata();
     this.getPackage();
  }
  
  getUser(){
    if(this.email==''){
      this.snackBar.openSnackBar('Please insert email','Close','red-snackbar');
    }else{
    let obj:any={};
    obj.email=this.email;
    this.spinner.show();
    this.apiService.getData('/user/getUserByMail', obj).then((data:any) => {
          this.userData=data.data;
          this.spinner.hide();
        },err => {
          this.spinner.hide();
    })
  }
  }

  getPackage(){
    let obj:any={};
    obj.email=this.email;
    this.spinner.show();
    this.apiService.getData('/package/list', obj).then((data:any) => {
          this.packageList=data.data;
          this.spinner.hide();
        },err => {
          this.spinner.hide();
    })
}

packageId:'';
selectPackage(packageObj:any){
  console.log(packageObj);
  this.packageId = packageObj._id;
}

purchasePackage(){
  if(this.userData._id==''){
    this.snackBar.openSnackBar('Please insert email','Close','red-snackbar');
  }else if(this.packageId=='' || !this.packageId){
    this.snackBar.openSnackBar('Please select package','Close','red-snackbar');
  }else{
    Swal.fire({
      title: 'Are you sure want add this record?',
      // text: 'You will not be able to recover this file!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Save it!',
      cancelButtonText: 'No, cancel it'
    }).then((result) => {
      if (result.value) {
            let obj ={
              companyId: this.userData._id,
              packageId: this.packageId
            }
            this.spinner.show();
            this.apiService.postData('/package/purchasePackage', obj).then((data:any) => {
                  this.snackBar.openSnackBar('package purchase','close', 'green-snackbar');
                  this.email ='';
                  this.userData={
                    name: '',
                    email: '',
                    contact: '',
                    address: '',
                    _id: ''
                  };
                  this.packageId='';
                  this.spinner.hide();
                },err => {
                  this.spinner.hide();
            })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("cancel")
      }
    })
  }

}


}
