import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { DataServiceService } from 'src/app/services/api-data-service';
import { MatSnackBarComponent } from 'src/app/sharecomponent/mat-snack-bar/mat-snack-bar.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-approve-member',
  templateUrl: './approve-member.component.html',
  styleUrls: ['./approve-member.component.css']
})
export class ApproveMemberComponent implements OnInit {

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  displayedColumns = ['name','package', 'enquiry','price','status','date'];
  purchaseData:any=[];
  
  dataSource:any;
  applyFilter(filterValue: string) {
   filterValue = filterValue.trim(); 
   filterValue = filterValue.toLowerCase();
   this.dataSource.filter = filterValue;
 }

  constructor(private apiService:DataServiceService,private snackBar: MatSnackBarComponent,public spinner : NgxSpinnerService) { }
  amountTotal: any =0;


  ngOnInit() {
      this.getData();
  }
  
  getData(){
    let obj:any={};
    this.spinner.show();
    this.apiService.getData('/package/purchasePackageUIAdmin',obj).then((data:any) => {
          this.purchaseData=data.data;
          console.log(this.purchaseData);
          this.dataSource = new MatTableDataSource( this.purchaseData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.amountTotal = this.verticalSum(this.purchaseData, 'amount');
          this.spinner.hide();
        },err => {
          this.spinner.hide();
    })
  }

  verticalSum(array, colname){
		let total = 0;
		array.forEach(item => {
			total += Number(item[colname] ? item[colname] : 0 );
		});
		return total.toFixed(2);
	}

}
