import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Foodies Katta';
  
  constructor(private translate: TranslateService) {
    localStorage.setItem('language','hindi');
    this.translatelang();
}
  translatelang(){
    let language=localStorage.getItem('language');
    this.translate.setDefaultLang(language);
  }
}
