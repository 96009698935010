import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
 //import { SidenavComponent } from './navigation/sidenav/sidenav.component';

const routes: Routes = [
  {
    path:'', 
    loadChildren : './website/website.module#WebsiteModule'
  },
  {
    path:'navigation' ,
    loadChildren:'./navigation/navigation.module#NavigationModule'
  }
 
];
@NgModule({
  
  imports: [RouterModule.forRoot(routes, { useHash: true,scrollPositionRestoration: 'top'  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
